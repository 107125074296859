package xyz.lacunae.games


import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section

val Games = FC<Props> { _ ->
    div {
        css {
            boxSizing = BoxSizing.borderBox
            width = 100.pct
            maxWidth = 800.px
            margin = Auto.auto
            padding = Padding(vertical = 0.px, horizontal = 1.6.rem)
        }
        section {
            h1 {
                +"Nos jeux"
            }
            p {
                +"Découvrez notre premier jeu narratif : « It's a date »."
            }
            Itsadate {
                details = true
            }
        }
        section {
            h1 {
                +"En développement"
            }
            p {
                +"Un petit aperçu de nos projets de jeux à venir"
            }
            Bermudes {
                details = true
            }
            div {
                css {
                    marginTop = 16.px
                }
            }
            ItsadateOtherStories {
                details = true
            }
            div {
                css {
                    marginTop = 16.px
                }
            }
            Itsadate2 {
                details = true
            }
        }
    }

}
