package xyz.lacunae.games


import csstype.Color
import csstype.NamedColor
import react.FC
import xyz.lacunae.common.GamePromotion

val Itsadate = FC<GameProps> { props ->
    GamePromotion {
        link = "//itsadate.lacunae.xyz"
        icon = "/public/games/icon_its_a_date.png"
        title = "It's a date"
        description = """Rencontres, alliances, trahisons.
            Découvrez comment vos choix peuvent transformer une histoire.
            """.trimIndent()
        gameTiming = 120
        backgroundColor = Color("rgba(255, 182, 193, 0.2)")
        backgroundColorHover = Color("rgba(255, 182, 193, 0.5)")
        details = props.details
    }
}