package xyz.lacunae.home

import csstype.Margin
import csstype.px
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import xyz.lacunae.games.Itsadate
import xyz.lacunae.others.LaVouivre

val Welcome = FC<Props> { _ ->
    div {
        css {
            margin = Margin(top = 32.px, bottom = 0.px, left = 0.px, right = 0.px)
        }
        Itsadate {}
        div {
            css {
                marginTop = 16.px
            }
        }
        LaVouivre {}
    }
}
