
import react.create
import react.dom.client.createRoot
import web.dom.document
import web.window.window

fun main() {
    window.onload = {
        val container = document.getElementById("root")!!
        val routes = Routes.create()
        createRoot(container).render(routes)
    }
}
