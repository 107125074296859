package xyz.lacunae.common

import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span

external interface GamePromotionProps : Props {
    var link: String
    var icon: String
    var title: String
    var description: String
    var authorName: String?
    var authorLink: String?
    var backgroundColor: Color
    var backgroundColorHover: Color
    var details: Boolean
    var gameTiming: Long?
}

val GamePromotion = FC<GamePromotionProps> { props ->
    a {
        href = props.link
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            borderRadius = 24.px
            backgroundColor = props.backgroundColor
            hover {
                backgroundColor = props.backgroundColorHover
            }
            padding = Padding(horizontal = 16.px, vertical = 16.px)
            color = NamedColor.black
            textDecoration = None.none
        }
        img {
            src = props.icon
            css {
                width = 72.px
                height = 72.px
                borderRadius = 48.px
                backgroundColor = NamedColor.white
            }
        }

        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.column
                margin = Margin(left = 16.px, right = 0.px, top = 0.px, bottom = 0.px)
            }
            span {
                css {
                    fontFamily = string("'Courier New', monospace")
                    fontSize = 1.6.rem
                    fontWeight = FontWeight.bolder
                    margin = Margin(left = 0.px, right = 0.px, top = 0.px, bottom = 8.px)
                }
                +props.title
            }
            p {
                css {
                    margin = Margin(horizontal = 0.px, vertical = 0.px)
                }
                props.description.split("\n").forEach {
                    +it
                    br {}
                }
            }
            if (props.details) {
                props.gameTiming?.let { gameTiming ->
                    p {
                        +"Temps de jeux: ${gameTiming}min"
                    }
                }
            }

            if (props.authorLink != null && props.authorName != null) {
                span {
                    +"Créé par "
                    a {
                        href = props.authorLink
                        +props.authorName
                    }
                }
            }
        }
    }

}