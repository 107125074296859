package xyz.lacunae.games


import csstype.Color
import csstype.NamedColor
import react.FC
import xyz.lacunae.common.GamePromotion

val ItsadateOtherStories = FC<GameProps> { props ->
    GamePromotion {
        link = "//itsadate.lacunae.xyz"
        icon = "/public/games/icon_its_a_date.png"
        title = "It's a date: Other Stories"
        description = """
            Prolongez l'expérience immersive de It's a date avec de nouvelles histoires annexes. Après la rencontre avec Jeanne/Simon, Lona/Louis, Coban et Roland, vivez de nouvelles aventures lors de votre connexion à CupidLov.
            Vous rencontrerez de nouveaux profils, traverserez de nouvelles épreuves. La vie est faite de choix anodins qui peuvent nous changer profondément.
            Ces histoires parallèles seront débloquées après que vous ayez fini le jeu une première fois.
        """.trimIndent()
        backgroundColor = Color("rgba(255, 182, 193, 0.2)")
        backgroundColorHover = Color("rgba(255, 182, 193, 0.5)")
        details = props.details
    }
}