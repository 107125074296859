package xyz.lacunae.common

import csstype.Auto
import csstype.pct
import csstype.px
import emotion.react.css
import react.*
import react.dom.html.ReactHTML.div

val Template = FC<PropsWithChildren> { props ->
    div {
        css {
            width = 100.pct
            maxWidth = 800.px
            margin = Auto.auto
        }
        Header {}
        +props.children
    }
}