package xyz.lacunae.games


import csstype.Color
import csstype.NamedColor
import react.FC
import xyz.lacunae.common.GamePromotion

val Itsadate2 = FC<GameProps> { props ->
    GamePromotion {
        link = "#"
        icon = "/public/games/icon_its_a_date.png"
        title = "It's a date 2"
        description = """
            Que sont devenus Jeanne/Simon, Lona/Louis, Coban et Roland ?
            Leur histoire est loin d'être terminée. Retrouvez ces personnages dans de nouvelles aventures toujours plus palpitantes.
            Ce sont vos choix qui écrivent l'histoire. Ferez-vous les bons ?
        """.trimIndent()
        backgroundColor = Color("rgba(255, 182, 193, 0.2)")
        backgroundColorHover = Color("rgba(255, 182, 193, 0.5)")
        details = props.details
    }
}