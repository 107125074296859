package xyz.lacunae.games


import csstype.Color
import csstype.NamedColor
import react.FC
import xyz.lacunae.common.GamePromotion

val Bermudes = FC<GameProps> { props ->
    GamePromotion {
        link = "#"
        icon = "/public/games/icon_bermude.png"
        title = "Bermudes"
        description =
            """Bermudes sera notre premier jeu feuilletonnant. 
A l’instar d’une série, de nouveaux épisodes vous seront proposés chaque mois.
Dans Bermudes, vous aurez la possibilité de voyager à travers les méandres du temps, en vivant des moments ayant façonné le monde dans lequel nous vivons.
Libre à vous de suivre docilement le cours de l’Histoire, ou bien de vous amuser à le réécrire.""".trimIndent()
        backgroundColor = Color("rgba(32, 178, 170, 0.2)")
        backgroundColorHover = Color("rgba(32, 178, 170, 0.5)")
        details = props.details
    }
}