package xyz.lacunae.others


import csstype.Color
import csstype.NamedColor
import react.FC
import xyz.lacunae.common.GamePromotion
import xyz.lacunae.games.GameProps

val LaVouivre = FC<GameProps> { props ->
    GamePromotion {
        link = "/others/la-vouivre/play/"
        icon = "/public/games/icon_others_la_vouivre.jpg"
        title = "La Vouivre"
        description = """Suivez les threads et choisissez votre destin. 
            Prenez garde, chaque décision a son importance !
            """.trimIndent()
        gameTiming = 45
        backgroundColor = Color("rgba(46, 139, 87, 0.2)")
        backgroundColorHover = Color("rgba(46, 139, 87, 0.5)")
        details = props.details
        authorName = "@Morolian"
        authorLink = "https://x.com/Morolian"
    }
}