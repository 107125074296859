import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.p
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter
import react.router.dom.NavLink
import xyz.lacunae.about.About
import xyz.lacunae.common.Template
import xyz.lacunae.games.Games
import xyz.lacunae.home.Welcome
import xyz.lacunae.notfound.NotFound
import xyz.lacunae.others.Others

val Routes = FC<Props> { _ ->
    BrowserRouter {
        Template {
            Routes {
                Route {
                    index = true
                    path = "/"
                    element = Welcome.create()
                }
                Route {
                    path = "/about"
                    element = About.create()
                }
                Route {
                    path = "/games"
                    element = Games.create()
                }
                Route {
                    path = "/others"
                    element = Others.create()
                }
                Route {
                    path = "*"
                    element = NotFound.create()
                }
            }
        }
    }
}