package xyz.lacunae.notfound


import csstype.Auto
import csstype.pct
import csstype.px
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.router.dom.Link

val NotFound = FC<Props> { _ ->
    div {
        css {
            width = 100.pct
            maxWidth = 800.px
            margin = Auto.auto
        }
        h1 {
            +"Page introuvable"
        }
        p {
            +"La page que vous recherchez n'est pas accessible"
        }
        Link {
            to = "/"
            +"Revenir à l'accueil"
        }
    }

}
