package xyz.lacunae.others


import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section

val Others = FC<Props> { _ ->
    div {
        css {
            boxSizing = BoxSizing.borderBox
            width = 100.pct
            maxWidth = 800.px
            margin = Auto.auto
            padding = Padding(vertical = 0.px, horizontal = 1.6.rem)
        }
        section {
            h1 {
                +"Les autres histoires"
            }
            p {
                +"Découvrez les histoires d'autres auteurs "
            }
            LaVouivre {
                details = true
            }
        }
    }

}
