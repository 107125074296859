package xyz.lacunae.about

import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.article
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.em
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.router.dom.Link

val About = FC<Props> { _ ->
    div {
        css {
            boxSizing = BoxSizing.borderBox
            width = 100.pct
            maxWidth = 800.px
            margin = Auto.auto
            padding = Padding(vertical = 0.px, horizontal = 1.6.rem)
        }
        h1 {
            +"Pourquoi "
            em { +"Lacunae" }
        }
        p {
            +"En réfléchissant à un nom qui correspondrait à l’idée que nous nous faisons des jeux narratifs, nous sommes tombés sur un "
            a {
                href = "https://fr.wikipedia.org/wiki/Livre-jeu#Encha%C3%AEnement_des_paragraphes"
                +"extrait d'interview de Paul Mason"
            }
            +", célèbre auteur de livres-jeux."
        }
        p {
            +"Il y explique le concept littéraire des lacunae, qui sont "
            em { +"« les trous dans la narration, que le lecteur remplit lui-même »" }
            +"."
        }
        p { +"Amateurs de ce type de jeux sur mobile, nous étions frustrés de ne pas assez « remplir les trous », et de nous apercevoir que dans de nombreuses histoires, le joueur subit le scénario plus qu’il n’y participe." }
        p {
            +"Avec "
            em { +"Lacunae" }
            +", nous souhaitons avant tout vous proposer les jeux dans lesquels nous aurions aimé nous plonger, et tenter de répondre à nos frustrations de joueurs en vous proposant des concepts originaux, pour des expériences aussi immersives que possible."
        }
        article {
            h1 {
                +"Les créateurs"
            }

            section {
                h1 {
                    +"Thomas Heurtier"
                }
                em { +"Scénariste" }
                p {
                    +""
                }
                p {
                    a {
                        href = "https://www.imdb.com/name/nm5770461/"
                        +"IMDb"
                    }
                    +" "
                    a {
                        href = "https://www.linkedin.com/in/thomas-heurtier-a1207061/"
                        +"LinkedIn"
                    }
                }
            }

            section {
                h1 {
                    +"Nicolas Mouchel"
                }
                em { +"Développeur" }
                p {
                    +""
                }

                p {
                    a {
                        href = "https://sagix.fr"
                        +"Site"
                    }
                    +" "
                    a {
                        href = "https://www.linkedin.com/in/nicolas-mouchel/"
                        +"LinkedIn"
                    }
                }
            }
        }
        Link {
            to = "/"
            +"Revenir à l'accueil"
        }
    }
}
