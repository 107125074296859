package xyz.lacunae.common

import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.ul
import react.router.dom.Link
import react.router.dom.NavLink
import react.router.useNavigate
import react.router.useNavigationType

val Header = FC<Props> {
    div {
        nav {
            ul {
                css {
                    display = Display.flex
                    listStyleType = None.none
                    padding = 0.px
                    margin = 0.px
                }
                li {
                    className = ClassName("HeaderNavStyles")
                    NavLink {
                        end = true
                        to = "/"
                        +"Accueil"
                    }
                }
                li {
                    className = ClassName("HeaderNavStyles")
                    NavLink {
                        to = "/games"
                        +"Nos jeux"
                    }
                }
                li {
                    className = ClassName("HeaderNavStyles")
                    NavLink {
                        to = "/others"
                        +"Autres jeux"
                    }
                }
                li {
                    css(ClassName("HeaderNavStyles")) {
                        margin = Margin(left = Auto.auto, right = 0.px, top = 0.px, bottom = 0.px)
                    }
                    NavLink {
                        to = "/about"
                        +"À propos"
                    }
                }
            }
        }
        Link {
            to = "/"
            className = ClassName("route_link_h1")
            h1 {
                css {
                    fontFamily = string("'Courier New', monospace")
                    fontSize = 5.rem
                    textAlign = TextAlign.center
                    padding = Padding(vertical = 5.vh, horizontal = 0.px)
                    textDecoration = None.none
                }
                +"Lacunae"
            }
        }
    }
}